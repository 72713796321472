import { render, staticRenderFns } from "./403.vue?vue&type=template&id=ed2eeb14&"
var script = {}
import style0 from "./403.vue?vue&type=style&index=0&id=ed2eeb14&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports